import { Grid, Typography } from "@material-ui/core"
import React, { ReactElement } from "react"
import InstagramEmbed from "react-instagram-embed"

import BlogPost from "../../../../components/BlogPost"
import { Link } from "../../../../components/Link"
import { SearchResults } from "../../../../components/SearchResults"

interface Props {
  data: any
  location: Location
  pageContext: any
}

export default function Post(props: Props): ReactElement {
  const { location, pageContext } = props
  return (
    <BlogPost
      location={location}
      pageContext={pageContext}
      title="Leder ist in"
      category="Wintertrends 2019/2020"
    >
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12} md={6}>
          <Typography paragraph={true}>
            Ledergehrock von Brigitte von Schönfels, Lederjacke im Used-Look von heine STYLE oder
            eine Lederjacke mit Gürtel von GUIDO MARIA KRETSCHMER aus hochwertigem Lammnappaleder,
            Gipsy Abendkleid aus echtem Leder mit seitlicher Nietenbordüre oder eine Liebeskind
            Berlin Henkeltasche aus weichem Glattleder - Leder kommt nie aus der Mode und ist diesen
            Herbst und auch Winter wieder im Trend. Leder ist zeitlos und hochwertig! Die Designer
            lieben Leder und zeigen uns auch dieses Jahr die schönsten{" "}
            <Link href="/handtaschen/" underline="always">
              Handtaschen
            </Link>
            , Stiefeletten und auch Midi-Röcke. Die High-Heel-Stiefeletten von Bronx aus
            hochwertigem Leder sind dabei ein absoluter Blickfang. Auch die Influcener lieben Leder.
            Jasmine Sanders trägt ein schulterfreies Midi-Kleid von Ermanno Scervino und zieht alle
            Blicke auf sich, denn das Leder-Kleid ist ein absoluter Eye-Catcher!
          </Typography>
          <InstagramEmbed
            url="https://www.instagram.com/p/B2wqgOOB6Q3/"
            hideCaption={true}
            injectScript={true}
          />
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <SearchResults
            breakpoints={{
              lg: 6,
              md: 6,
              xs: 6,
            }}
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=24608409015&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/29425909?w=2216&h=2500",
                    title: "",
                  },
                ],
                name: "Bronx",
                price: 170,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24688083489&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/34819938?w=1200&h=1500",
                    title: "",
                  },
                ],
                name: "Brigitte von Schönfels",
                price: 329.94,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24953404923&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src:
                      "https://i.otto.de/i/otto/ae01e230-7b0d-5f4e-8fab-82bfff43bf4d?w=1430&h=2000",
                    title: "",
                  },
                ],
                name: "heine STYLE",
                price: 199.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24608780207&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/33829089?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "GUIDO MARIA KRETSCHMER",
                price: 249.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24609278121&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/33517401?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "Gipsy",
                price: 129,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25323060229&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/34280175?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "Bruno Banani",
                price: 199.99,
              },
            ]}
          />
        </Grid>
      </Grid>

      <Grid container={true} spacing={1}>
        <Grid xs={12} md={12}>
          <SearchResults
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=24608403889&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/29331180?w=2500&h=2134",
                    title: "",
                  },
                ],
                name: "GADEA",
                price: 186.75,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25222050429&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/35922550?w=900&h=1200",
                    title: "",
                  },
                ],
                name: "Y.A.S",
                price: 289.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25518572979&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/36962576?w=1060&h=1253",
                    title: "",
                  },
                ],
                name: "Liebeskind Berlin",
                price: 299,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24626993687&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/34856552?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "TOSCA BLU",
                price: 243,
              },
            ]}
          />
        </Grid>
      </Grid>
    </BlogPost>
  )
}
